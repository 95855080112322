<section>
    <div class="page-blocks">
        <div style="float: right;height: 150px;width: 100%;margin-bottom: 20px;">
            <img class="logo_Eryma" src="../../../assets/image/logo_Eryma.png" alt="logo_Eryma image">
        </div>
        <div style="float: left;min-height: 40px;width: 100%;">
            <p class="ft01">
                <span>
                    Cher Client, chère Cliente,
                </span>
            </p>
        </div>
        <div style="float: left;min-height: 40px;width: 100%;">
            <p class="ft02">
                <span>
                    <b class="ft02">ERYMA évolue …</b>
                </span>
            </p>
        </div>
        <div style="float: left;min-height: 40px;width: 100%;margin-bottom: 15px;">
            <p class="ft01">
                <span>
                    Accédez dorénavant à l’ensemble de vos services souscrits via votre nouvel espace Client<b style="padding-left: 10px;" class="ft02">myERYMA</b>
                </span>
            </p>
        </div>
        <div style="float: left;min-height: 40px;width: 100%;margin-bottom: 15px;">
            <p class="ft02">
                <span>
                    <b class="ft02">… et vous propose encore plus de services !</b>
                </span>
            </p>
        </div>
        <div style="float: left;min-height: 40px;width: 100%;margin-bottom: 15px;">
            <p class="ft03" style="width: 2%;float: left;">
                <span></span>
            </p>
            <p style="width: 90%;float: left;margin-left: 15px;">
                <span class="ft04"><i><b>Un espace Client sécurisé : </b></i></span>
                <span class="ft01">disponible sur votre smartphone, tablette ou ordinateur et accessible depuis le site web ERYMA avec une authentification à double facteurs.</span>
            </p>
        </div>
        <div style="float: left;min-height: 40px;width: 100%;margin-bottom: 15px;">
            <p class="ft03" style="width: 2%;float: left;">
                <span></span>
            </p>
            <p style="width: 90%;float: left;margin-left: 15px;">
                <span class="ft04"><i><b>Un espace personnalisé : </b></i></span>
                <span class="ft01">tous vos services souscrits accessibles en un seul clic sur une même plateforme.</span>
            </p>
        </div>
        <div style="float: left;min-height: 40px;width: 100%;margin-bottom: 15px;">
            <p class="ft03" style="width: 2%;float: left;">
                <span></span>
            </p>
            <p style="width: 90%;float: left;margin-left: 15px;">
                <span class="ft04"><i><b>Une navigation simplifiée : </b></i></span>
                <span class="ft01">utilisation d’un même identifiant pour l’ensemble de vos services.</span>
            </p>
        </div>
        <div style="float: left;min-height: 250px;width: 100%;margin-bottom: 15px;text-align: center;">
            <div style="float: left;min-height: 40px;width: 100%;margin-bottom: 15px;">
                <p class="ft02">
                    <span>
                        <b>Découvrez notre nouveau portail en cliquant sur le logo ci-dessous</b>
                    </span>
                </p>
            </div>
            <div style="float: left;min-height: 150px;width: 100%;margin-bottom: 25px;">
                <a href="{{urlMyEryma}}">
                    <img class="logo" src="../../../assets/image/logo.png" alt="logo image">
                </a>
            </div>
            <div style="float: left;height: 40px;width: 100%;margin-bottom: 15px;">
                <p class="ft05">
                    <span>
                        <b>A bientôt sur votre nouvel espace Client myERYMA !</b>
                    </span>
                </p>
            </div>
        </div>    
    </div>
    <div style="float: left;min-height: 40px;width: 100%;text-align: center;margin-bottom: 10px;">
        <p class="ft00">
            <span>
                Vous serez redirigé automatiquement vers votre espace Client myERYMA après 30 secondes d’inactivité
            </span>
        </p>
    </div>
</section>