import { Injectable } from '@angular/core';
import { Config } from 'protractor';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  config: Config;

  constructor(private readonly http: HttpClient) {
    this.config = { api: '', apiAuth: '', version: '' };
  }

  loadConfig() {
    return this.http
      .get<Config>('./assets/config.json')
      .toPromise()
      .then(config => {
        this.config = config;
      });
  }
}
