import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../service/config.service';


@Component({
  selector: 're-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  urlMyEryma = 'https://my.eryma.net';

  constructor(configService: ConfigService) {
    this.urlMyEryma = configService.config.urlMyEryma;
   }

  ngOnInit() {
    this.loadAll();
  }

  loadAll() {
    setInterval(() => {
      window.location.href = this.urlMyEryma;
      }, 30 * 1000);
  }
}
